.error-header-bar {
  background-color: #303030;
  /* padding: 1.2% 0; */
  padding: 13.8p 0;
}

.error-logo-container {
  width: 90%;
  margin: 0 auto;
  position: relative;
  height: 72px; /* Adjusted for larger header-bar */
  /* height: 13rem; */
}

.error-logo-container img {
  position: absolute;
  /* height: 120%; */
  left: 0%;
  width: 72px;
  height: auto;
}

.error-main-container {
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 50px 0;
}

.error-content {
  width: 60%;
  text-align: center;
}

.error-welcome-message,
.error-contact-message {
  color: #333;
  line-height: 1.9;
  margin-bottom: 30px;
  font-size: 1.8rem;
}

a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .error-header-bar {
    padding: 3% 0; /* Increased padding for mobile */
  }
  .error-logo-container img {
    left: 2.5%;
    height: 100%; /* Adjust logo size for mobile */
  }
  .error-content {
    width: 80%;
  }
}
